import * as R from 'ramda';
import { useCallback } from 'react';

import { API } from 'services';
import * as M from 'types/serverModels';
import { makePrimaryUnit, makeDerivedUnit } from 'utils/State';
import { ProjectBookChange } from 'utils/models';

import {
  ProjectBookUser,
  ProjectXShortsState,
  ProjectBookChangePatch,
} from './types';

export const projectBookUnit = makePrimaryUnit<M.ProjectBook | null>(null);
export const projectBookUserUnit = makePrimaryUnit<
  ProjectBookUser | null | undefined
>(undefined);
export const projectBookUsersUnit = makePrimaryUnit<M.UserXShortRecord | null>(
  null,
);
export const groupXShortsUnit = makePrimaryUnit<M.GroupXShortRecord | null>(
  null,
);
export const projectXShortsUnit = makePrimaryUnit<ProjectXShortsState | null>(
  null,
);
export const commentStatsUnit =
  makePrimaryUnit<M.ProjectBookCommentStats | null>(null);

export const projectBookChangeUnit =
  makePrimaryUnit<ProjectBookChangePatch | null>(null);

export const projectBookStageStatusPutCallStateUnit =
  API.services.projectBook.stage.status.put.makeCallStateUnit();
export const projectBookNotificationConfigPutCallStateUnit =
  API.services.projectBook.notificationConfig.put.makeCallStateUnit();
export const projectBookPatchCallStateUnit =
  API.services.projectBook.patch.makeCallStateUnit();

export const projectBookSaveCallStateUnit = makeDerivedUnit(
  projectBookStageStatusPutCallStateUnit,
  projectBookNotificationConfigPutCallStateUnit,
  projectBookPatchCallStateUnit,
).getUnit(API.makeCallStateUnitsDeriver('partial'));

function updateStateUnits(
  callState: API.CallState<{
    projectBook: M.ProjectBook;
    users: M.UserXShortRecord;
    groups: M.GroupXShortRecord;
    autoChanges: M.ProjectBookAutoChange[];
  }>,
) {
  if (callState.kind !== 'successful') {
    return;
  }

  projectBookUnit.setState(
    prev =>
      prev && {
        ...prev,
        current_stage_number: callState.data.projectBook.current_stage_number,
        skills: {
          ...(prev.skills || {}),
          max_score: callState.data.projectBook.skills?.max_score,
          max_weighted_score:
            callState.data.projectBook.skills?.max_weighted_score,
        },
        evaluation: callState.data.projectBook.evaluation,
        ...callState.data.autoChanges.reduce<
          Pick<M.ProjectBook, 'status' | 'stages'>
        >(
          (acc, x) => {
            switch (x.change?.event) {
              case 'status_change': {
                return { ...acc, status: x.change.after || undefined };
              }
              case 'stages_status_change': {
                if (!acc.stages?.length) {
                  break;
                }

                const stage = ProjectBookChange.findStage(x.change, prev);

                if (stage === undefined) {
                  break;
                }

                return {
                  ...acc,
                  stages: R.update(
                    acc.stages.findIndex(y => y.uuid === stage.uuid),
                    { ...stage, status: x.change.after || undefined },
                    acc.stages,
                  ),
                };
              }
            }

            return { ...acc };
          },
          {
            status: prev.status,
            stages: prev.stages,
          },
        ),
      },
  );
  callState.data.autoChanges.forEach(x => {
    projectBookChangeUnit.setState({
      kind: 'after',
      data: x,
      projectBook: projectBookUnit.getState(),
    });
  });
  projectBookUsersUnit.setState(callState.data.users);
  groupXShortsUnit.setState(callState.data.groups);
}

projectBookPatchCallStateUnit.subscribe({
  name: 'state-units-updater',
  callback: updateStateUnits,
});

projectBookStageStatusPutCallStateUnit.subscribe({
  name: 'state-units-updater',
  callback: updateStateUnits,
});

export function useProjectBookSave() {
  const call = API.services.projectBook.patch.useCall(
    projectBookPatchCallStateUnit,
  );

  const projectBookSaveCall = useCallback(
    (
      updates: M.ProjectBook | ((projectBook: M.ProjectBook) => M.ProjectBook),
    ) => {
      const projectBookUser = projectBookUserUnit.getState();
      const projectBook = projectBookUnit.getState();
      const uuid = projectBook?.uuid;

      if (projectBook === null || uuid === undefined) {
        return;
      }

      const projectBookChanges =
        typeof updates === 'function' ? updates(projectBook) : updates;

      const newProjectBook = {
        ...projectBook,
        ...projectBookChanges,
      };

      projectBookUnit.setState(newProjectBook);

      call({
        uuid,
        project_book: projectBookChanges,
        user: projectBookUser !== null ? projectBookUser : undefined,
      });
    },
    [call],
  );

  return {
    projectBookSaveCall,
    projectBookSaveCallStateUnit,
  };
}
