import { MathJax3Config, MathJaxContext } from 'better-react-mathjax';
import React from 'react';

import { ErrorFallback } from 'features/global';
import { Feature, ThemeProvider, IDProvider } from 'services';

type Props = React.PropsWithChildren<{}>;

const mathJaxConfig: MathJax3Config = {
  tex: {
    inlineMath: [['\\(', '\\)']],
  },
};

function AppProviders({ children }: Props) {
  return (
    <Feature.FallbackContext.Provider
      FallbackComponent={ErrorFallback.Component}
    >
      <MathJaxContext
        config={mathJaxConfig}
        src={`${process.env.RAZZLE_PATH_BASENAME}/static/js/tex-chtml.js`}
      >
        <ThemeProvider.Component>
          <IDProvider.Component>{children}</IDProvider.Component>
        </ThemeProvider.Component>
      </MathJaxContext>
    </Feature.FallbackContext.Provider>
  );
}

export const Component = AppProviders;
