import React, { createContext, useContext } from 'react';
import { Route } from 'react-router';

import * as SwitchPageMode from 'features/SwitchPageMode';
import * as global from 'features/global';
import * as MainLayout from 'features/global/MainLayout';
import * as Feature from 'services/Feature';

import * as PageFeaturesRegistry from '../PageFeaturesRegistry';
import * as ScrollTo from './ScrollTo';
import { PageConstructorArgs } from './types';

const PageContext = createContext<boolean>(false);

export function makePage<T extends PageConstructorArgs<any, any>>(
  pageData: T,
): React.FC<any> {
  const flatFeatures = Feature.flattenFeatures([
    ...pageData.features,
    ...Object.values(global).map(x => x.feature),
    MainLayout.feature,
  ]);

  const endOption = (() => {
    switch (pageData.routeProps?.exact) {
      case true:
        return true;
      case undefined:
      case false:
        return false;
    }
  })();

  if (Array.isArray(pageData.path)) {
    pageData.path.forEach(x => {
      PageFeaturesRegistry.setPageFeatures(x, flatFeatures, {
        end: endOption,
      });
    });
  } else {
    PageFeaturesRegistry.setPageFeatures(pageData.path, flatFeatures, {
      end: endOption,
    });
  }

  return (props: any) => {
    const mode = SwitchPageMode.pageModeUnit.useState();

    const isInPageContext = useContext(PageContext);

    return (
      <Route path={pageData.path} {...pageData.routeProps}>
        <PageContext.Provider value={true}>
          {(() => {
            if (isInPageContext) {
              return (
                <>
                  <ScrollTo.Component scrollTo={pageData.scrollTo} />
                  <pageData.Component {...props} />
                </>
              );
            }

            return mode === 'user' ? (
              <>
                <ScrollTo.Component scrollTo={pageData.scrollTo} />
                <pageData.Component {...props} />
              </>
            ) : (
              <>
                <ScrollTo.Component scrollTo="top-on-mount" />
                <global.I18nEdit.Component />
              </>
            );
          })()}
        </PageContext.Provider>
      </Route>
    );
  };
}
