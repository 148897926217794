import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { PrimaryStateUnit, makeDerivedUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

const projectBookLevelRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.ProjectBookLevelReferenceBook[]>
  >;

const projectBookLevelCallStateUnit = makeDerivedUnit(
  projectBookLevelRefsCallStateUnit,
).getUnit<
  CallState<{
    [key in M.ProjectBookLevelReferenceBook['code']]: M.ProjectBookLevelReferenceBook;
  }>
>(projectBookLevelRefsCallState => {
  if (projectBookLevelRefsCallState.kind === 'successful') {
    const data = projectBookLevelRefsCallState.data.reduce<{
      [key in M.ProjectBookLevelReferenceBook['code']]: M.ProjectBookLevelReferenceBook;
    }>((acc, x) => {
      return {
        ...acc,
        [x.code]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return projectBookLevelRefsCallState;
});

let wasCalled = false;

function useProjectBookLevelRefsCallState() {
  const callState = projectBookLevelRefsCallStateUnit.useState();

  const call = services.refLoad.useCall(
    projectBookLevelRefsCallStateUnit as PrimaryStateUnit<
      CallState<M.ReferenceBook[]>
    >,
  );

  useEffect(() => {
    if (process.env.RAZZLE_PROFILE === 'MESCH') {
      projectBookLevelRefsCallStateUnit.setState({
        kind: 'successful',
        data: [],
      });

      return;
    }

    if (!wasCalled) {
      wasCalled = true;

      call({ ref: 'pb_level' });
    }
  }, [callState.kind, call]);
}

export function useCallState() {
  const callState = projectBookLevelCallStateUnit.useState();

  useProjectBookLevelRefsCallState();

  return callState;
}
