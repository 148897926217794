import { I18n } from 'services';
import * as M from 'types/serverModels';

type Keys = 'null' | Exclude<M.ProjectBookEvaluationCriteriaValue, null>;

type References<T extends Keys> = { [key in T]?: I18n.EntryReference };

export function useDataText<T extends Keys>(references: References<T>) {
  const text = Object.entries(references).reduce(
    (acc, [key, x]) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return { ...acc, [key]: I18n.useReference(x as I18n.EntryReference) };
    },
    {} as { [key in T]: string },
  );

  return text;
}
